import React, { useContext, useEffect, useRef, useState } from "react";
import SearchIcon from "../assets/images/search-lg-icon.svg";
import filterLines from "../assets/images/filter-lines.svg";
import downloadIcon from "../assets/images/download-icon.svg";
import trashIcon from "../assets/images/trash-icon.svg";
import plusIcon from "../assets/images/plus-icon.svg";
import threeDots from "../assets/images/dots-vertical.svg";
import close from "../assets/images/x-close.svg";
import { Space, Table } from "antd";
import _ from "lodash";
import searchicon from "../assets/images/search-lg-icon.svg";

import {
  PostCallWithErrorResponse,
  simpleGetCall,
  simpleGetCallWithErrorResponse,
} from "../Api/ApiServices";
import { ApiConfig } from "../Api/ApiConfig";
import NoDataFound from "../component/noDataFound";

import {
  div,
  Button,
  Form,
  Select,
  option,
  FormControl,
  FormGroup,
} from "react-bootstrap";
import { toastMsg } from "../component/toast";
import { render } from "@testing-library/react";
import AppState from "../context/AppState";
import { AppContext } from "../context/AppContext";
import { hover } from "@testing-library/user-event/dist/hover";

const GroupList = (props) => {
  const { codeRespondendHandler, clearCode } = props;

  const [valiteded, setValiteded] = useState(false);

  const [checkStrictly, setCheckStrictly] = useState(false);

  const [isActive, setIsActive] = useState(true);
  const [isActivewin, setIsActivewin] = useState(false);

  const profilePer = {
    codeId: 0,
    id: 0,
    pioneer: "",
    influencer: "",
    teamPlayer: "",
    logicalThinker: "",
    persuader: "",
    achiever: "",
    perfectionist: "",
    collaborator: "",
    assessor: "",
    implementor: "",
    motivator: "",
    leader: "",
    administrator: "",
    mediator: "",
    versatile: "",
  };

  const initialState = {
    id: 0,
    accessCode: "",
    reason: "",
    company: "",
    designation: "",
    additionalQs1: "no",
    additionalQs2: "no",
    questionOne: "",
    questionTwo: "",
    questionThree: "",
    address: "",
    linktoAssess: "",
    idealExp: "",
    eduLevel: "",
    eduArea: "",
    industry: "",
    salaryRange: "",
    startDate: "",
    primaryMatch: "",
    secoundryMatch: "",
    primaryProfile: "",
    secoundryProfile: "",
    association: "",
    isActive: true,
    from : "",
  };

  const [details, setDetails] = useState(initialState);

  const { adminData } = useContext(AppContext);

  const [initialProfiles, setInitialProfiles] = useState(profilePer);

  const [isEdit, setIsEdit] = useState(false);

  const [show, setShow] = useState(false);
  const [reqfield, setReqfield] = useState(null);
  const handleClose = () => {
    setShowModal(false);
    setDetails(initialState);

    setEnterEmail("");

    setShowEmails([]);

    setIsEdit(false);

    setValiteded(false);

    setInitialProfiles(profilePer);

    setShow(false);
  };
  const handleShow = () => setShow(true);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      console.log("File selected:", file.name);
      // You can add further logic to handle the file upload here
    }
  };

  const [groupListData, setGroupListData] = useState([]);

  const [isLoadin, setIsLoading] = useState(true);

  const [rowsToShow, setRowsToShow] = useState(10);

  const handleRowsToShowChange = (event) => {
    setRowsToShow(Number(event.target.value));
  };

  const getData = () => {
    const url = `${ApiConfig.GROUP_LIST}${JSON.parse(
      localStorage.getItem("email")
    )}&IsActive=${isActive}`;
    simpleGetCallWithErrorResponse(url)
      .then((res) => {
        console.log(res);

        if (res?.json?.succeded) {
          setGroupListData(res?.json?.response);
          console.log(res?.json?.data?.length);
        } else {
          console.log("something went wrong");
        }

        setIsLoading(false);
      })
      .catch((err) => {
        console.log("something went wrong");

        setIsLoading(false);
      });
  };

  useEffect(() => {
    //codeRespondendHandler('')
    clearCode();
    getData();
  }, [isActive]);

  const copyCode = (code) => {
    navigator.clipboard.writeText(
      // "https://lywouserweb.app.cloudxperte.com/?code=" + code
      // "http://localhost:3000/?code=" + code
      "http://apply.lywo.in/?code=" + code
    );
  };

  const [error, setError] = useState("");
  const [errorAdd, setErrorAdd] = useState("");

  // const handleSubmit = (event) => {
  //   console.log(details);

  //   event.preventDefault();
  //   event.stopPropagation();
  //   const form = event.currentTarget;
  //   if (form.checkValidity() == false) {
  //     console.log(form.checkValidity());

  //     setValiteded(true);
  //   }

  //   else {
  //     if (details.accessCode.length != 7) {
  //       toastMsg("error", "please Enter 7 Digit Valid Access Code");
  //       return;
  //     }

  //     if (showEmails.length == 0 && details.reason === "Job Application") {
  //       toastMsg("error", "Please Add Users");
  //       return;
  //     }

  //     PostCallWithErrorResponse(ApiConfig?.ADD_JOB, {
  //       id: details?.id,
  //       isActive: details?.isActive,
  //       code: details?.accessCode,
  //       codeAssociation: details?.association,
  //       position: details?.designation,
  //       reason: details?.reason,
  //       company: details?.company,
  //       additionalPart1: details.additionalQs1,
  //       additionalPart2: details.additionalQs2,
  //       primaryId: details?.primaryProfile,
  //       secondaryId: details?.secoundryProfile,
  //       primaryMatch: details?.primaryMatch,
  //       secondaryMatch: details.secoundryMatch,
  //       profilePer: details.reason === "Job Application" ? initialProfiles : {},
  //       users: showEmails,

  //       additionalPart3: details?.additionalQs3,
  //       addressOfSheet: details?.address,
  //       linkToAssessment: details?.linktoAssess,
  //       idealExperience: details?.idealExp,
  //       idealEducationLevel: details?.eduLevel,
  //       idealEducationArea: details?.eduArea,
  //       idealIndustry: details?.industry,
  //       idealSalaryRange: details?.salaryRange,
  //       idealStartDate: details?.startDate,
  //     })
  //       .then((res) => {
  //         console.log(res);

  //         if (res?.json?.succeded) {
  //           setIsEdit(false);
  //           toastMsg("success", res?.json?.response?.statusMessage);
  //           getData();
  //           handleClose();
  //         } else {
  //           toastMsg("error", res?.json?.response?.statusMessage);
  //         }
  //       })
  //       .catch((err) => {
  //         console.log("something wrong");
  //       });
  //   }
  // };

  // const handleSubmit = (event) => {
  //   event.preventDefault();
  //   event.stopPropagation();

  //   const form = event.currentTarget;
  //   if (!form.checkValidity()) {
  //     console.log(form.checkValidity());
  //     setValiteded(true);
  //     return;
  //   }

  //   if (!details) {
  //     console.error("Details are not defined");
  //     return;
  //   }

  //   const urlPattern = /^(https?:\/\/)?([\w-]+\.)+[\w-]+(\/[\w-./?%&=]*)?$/;
  //   if (!urlPattern.test(details.linktoAssess)) {
  //     setError("Please enter a valid hyperlink (e.g., https://example.com)");
  //     return;
  //   } else {
  //     setError("");
  //   }

  //   if (details.accessCode.length !== 7) {
  //     toastMsg("error", "Please enter a 7-digit valid access code");
  //     return;
  //   }

  //   if (showEmails.length === 0 && details.reason === "Job Application") {
  //     toastMsg("error", "Please add users");
  //     return;
  //   }

  //   const payload = {
  //     id: details.id,
  //     isActive: details.isActive,
  //     code: details.accessCode,
  //     codeAssociation: details.association,
  //     position: details.designation,
  //     reason: details.reason,
  //     company: details.company,
  //     additionalPart1: details.additionalQs1,
  //     additionalPart2: details.additionalQs2,
  //     primaryId: details.primaryProfile,
  //     secondaryId: details.secoundryProfile,
  //     primaryMatch: details.primaryMatch,
  //     secondaryMatch: details.secoundryMatch,
  //     profilePer: details.reason === "Job Application" ? initialProfiles : {},
  //     users: showEmails,
  //     additionalPart3: details.additionalQs3,
  //     addressOfSheet: details.address,
  //     linkToAssessment: details.linktoAssess,
  //     idealExperience: details.idealExp,
  //     idealEducationLevel: details.eduLevel,
  //     idealEducationArea: details.eduArea,
  //     idealIndustry: details.industry,
  //     idealSalaryRange: details.salaryRange,
  //     idealStartDate: details.startDate,
  //   };

  //   // Proceed with API call only if all validations pass
  //   PostCallWithErrorResponse(ApiConfig?.ADD_JOB, payload)
  //     .then((res) => {
  //       if (res?.json?.succeded) {
  //         setIsEdit(false);
  //         toastMsg("success", res?.json?.response?.statusMessage);
  //         getData();
  //         handleClose();
  //       } else {
  //         toastMsg("error", res?.json?.response?.statusMessage);
  //       }
  //     })
  //     .catch((err) => {
  //       console.error("Something went wrong:", err);
  //     });
  // };

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;
    if (!form.checkValidity()) {
      console.log(form.checkValidity());
      setValiteded(true);
      return;
    }

    if (!details) {
      console.error("Details are not defined");
      return;
    }

    const urlRegex = /^(https?:\/\/[^\s$.?#].[^\s]*)$/;
    const googleDocsRegex = /https?:\/\/docs\.google\.com\//;

    // const customQuestion1 = details.additionalQs1.trim();
    // if (customQuestion1 && customQuestion1 !== "YES" && customQuestion1 !== "NO") {
    //   toastMsg("error", "Custom Question 1 must be 'YES' or 'NO' if entered");
    //   return;
    // }

    // const customQuestion2 = details.additionalQs2.trim();
    // if (customQuestion2 && customQuestion2 !== "YES" && customQuestion2 !== "NO") {
    //   toastMsg("error", "Custom Question 2 must be 'YES' or 'NO' if entered");
    //   return;
    // }

    // const customQuestion3 = details.additionalQs3.trim();
    // if (customQuestion3 && customQuestion3 !== "YES" && customQuestion3 !== "NO") {
    //   toastMsg("error", "Custom Question 3 must be 'YES' or 'NO' if entered");
    //   return;
    // }

    if (details.address && !googleDocsRegex.test(details.address)) {
      toastMsg(
        "error",
        "Please enter a valid Google Document link for 'Address of the Sheet"
      );
      return;
    }

    if (details.linktoAssess && !urlRegex.test(details.linktoAssess)) {
      toastMsg(
        "error",
        "Please enter a valid hyperlink for 'Link to Assessment'"
      );
      return;
    }

    if (details.accessCode.length !== 7) {
      toastMsg("error", "Please enter a 7-digit valid access code");
      return;
    }

    if (showEmails.length === 0 && details.reason === "Job Application") {
      toastMsg("error", "Please add users");
      return;
    }

    const payload = {
      id: details.id,
      isActive: details.isActive,
      code: details.accessCode,
      codeAssociation: details.association,
      position: details.designation,
      reason: details.reason,
      company: details.company,
      additionalPart1: details.additionalQs1,
      additionalPart2: details.additionalQs2,
      primaryId: details.primaryProfile,
      secondaryId: details.secoundryProfile,
      primaryMatch: details.primaryMatch,
      secondaryMatch: details.secoundryMatch,
      profilePer: details.reason === "Job Application" ? initialProfiles : {},
      users: showEmails,
      question1: details.questionOne,
      question2: details.questionTwo,
      question3: details.questionThree,
      addressOfSheet: details.address,
      fromEmail: details.from,
      linkToAssessment: details.linktoAssess,
      idealExperience: details.idealExp,
      idealEducationLevel: details.eduLevel,
      idealEducationArea: details.eduArea,
      idealIndustry: details.industry,
      idealSalaryRange: details.salaryRange,
      idealStartDate: details.startDate,
    };

    PostCallWithErrorResponse(ApiConfig?.ADD_JOB, payload)
      .then((res) => {
        if (res?.json?.succeded) {
          setIsEdit(false);
          toastMsg("success", res?.json?.response?.statusMessage);
          getData();
          handleClose();
        } else {
          toastMsg("error", res?.json?.response?.statusMessage);
        }
      })
      .catch((err) => {
        console.error("Something went wrong:", err);
      });
  };

  const paginationConfig = {
    pageSizeOptions: [
      "10",
      "20",
      "30",
      "40",
      "50",
      "60",
      "70",
      "80",
      "90",
      "100",
    ], // Options for items per page
    defaultPageSize: 50, // Default number of items per page
    showSizeChanger: false, // Show the dropdown to change page size
    onChange: (page, pageSize) => {
      console.log("Page:", page, "PageSize:", pageSize);
    },
  };

  const handleChage = (key, val) => {
    if (key === "reason") {
      let prt1 = "";
      let part2 = "";

      if (
        val === "Individual User" ||
        val === "Investor" ||
        val === "Investor as Individual User"
      ) {
        prt1 = "no";
        part2 = "no";
      }

      if (val === "Job Application") {
        prt1 = "yes";
        part2 = "no";
      }

      if (val === "Internal Assessment") {
        prt1 = "no";
        part2 = "yes";
      }

      setDetails((prev) => ({
        ...prev,
        reason: val,
        additionalQs1: prt1,
        additionalQs2: part2,
      }));
      const urlPattern = /^(https?:\/\/)?([\w-]+\.)+[\w-]+(\/[\w-./?%&=]*)?$/;
      if (!urlPattern.test(details.linktoAssess)) {
        setError("Please enter a valid hyperlink (e.g., https://example.com)");
      } else {
        setError("");
      }

      return;
    }

    setDetails((prev) => ({
      ...prev,
      [key]: val,
    }));
  };
  // const [enterEmail, setEnterEmail] = useState("");
  // const [showEmails, setShowEmails] = useState([]);
  // const [showed, setShowed] = useState(false);

  // const handleAddEmail = () => {
  //   setShowEmails([...showEmails, enterEmail]);
  //   setShowed(true);
  //   setEnterEmail("");
  // };
  const [enterEmail, setEnterEmail] = useState("");
  const [showEmails, setShowEmails] = useState([]);
  const [showed, setShowed] = useState(false);

  const handleAddEmail = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (enterEmail.trim() !== "") {
      if (!emailRegex.test(enterEmail)) {
        toastMsg("error", "Please Enter Valid User Email");
        return;
      }

      let index = showEmails.findIndex((i) => i.email === enterEmail);

      if (index != -1) {
        toastMsg("error", "Email Already Added");
        return;
      }

      setShowEmails([
        ...showEmails,
        {
          codeId: 0,
          email: enterEmail,
        },
      ]);
      setShow(true);
      setEnterEmail("");
    }
  };

  const removeEmail = (indexToRemove) => {
    const updatedEmails = showEmails.filter(
      (_, index) => index !== indexToRemove
    );
    setShowEmails(updatedEmails);
  };

  const handleProfiles = (key, val) => {
    setInitialProfiles((prev) => ({
      ...prev,
      [key]: val,
    }));
  };

  const filterData = (data) => (formatter) =>
    data.map((item) => ({
      text: formatter(item),
      value: formatter(item),
    }));
  const [filteredInfo, setFilteredInfo] = useState({});
  const [sortedInfo, setSortedInfo] = useState({});

  const handleChange = (pagination, filters, sorter) => {
    console.log("Various parameters", pagination, filters, sorter);
    setFilteredInfo(filters);
    setSortedInfo(sorter);
  };
  const columns = [
    {
      title: "Access Code",
      render: (record) => <h5 className="link-text">{record}</h5>,
      dataIndex: "code",
      sorter: (a, b) => a.code.localeCompare(b.code),
      filters: _.uniqWith(
        filterData(groupListData)((i) => i.code),
        _.isEqual
      ),
      filteredValue: filteredInfo.code || null,
      filterSearch: true,
      onFilter: (value, record) =>
        record.code.toString().toLowerCase().includes(value.toLowerCase()),
      onCell: (record, rowIndex) => {
        return {
          onClick: (env) => {
            codeRespondendHandler(
              record?.code,
              record?.company,
              record?.designation,
              record?.reason
            );
          },
        };
      },
    },

    {
      title: "Form Type",
      dataIndex: "reason",
      sorter: (a, b) => a.reason?.localeCompare(b.reason),
      filters: _.uniqWith(
        filterData(groupListData)((i) => i.reason),
        _.isEqual
      ),
      filteredValue: filteredInfo.reason || null,
      filterSearch: true,
      onFilter: (value, record) =>
        record.reason.toString().toLowerCase().includes(value.toLowerCase()),
    },
    {
      title: "Company",
      dataIndex: "company",
      sorter: (a, b) => a.company?.localeCompare(b.company),
      filters: _.uniqWith(
        filterData(groupListData)((i) => i.company),
        _.isEqual
      ),
      filteredValue: filteredInfo.company || null,
      filterSearch: true,
      onFilter: (value, record) =>
        value === ""
          ? record.company.toString().toLowerCase() === value.toLowerCase()
          : record.company
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase()),
    },
    {
      title: "Designation",
      dataIndex: "designation",
      sorter: (a, b) => a.designation.localeCompare(b.designation),
      filters: _.uniqWith(
        filterData(groupListData)((i) => i.designation),
        _.isEqual
      ),
      filteredValue: filteredInfo.designation || null,
      filterSearch: true,
      onFilter: (value, record) =>
        value === ""
          ? record.designation.toString().toLowerCase() === value.toLowerCase()
          : record.designation
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase()),
    },
    // {
    //   title: "Add'l Qs Part 1",
    //   dataIndex: "additionalPart1",
    //   sorter: (a, b) => a.additionalpart1 - b.additionalpart1,
    //   filters: _.uniqWith(
    //     filterData(groupListData)((i) => i.additionalPart1),
    //     _.isEqual
    //   ),
    //   filteredValue: filteredInfo.additionalPart1 || null,
    //   filterSearch: true,
    //   onFilter: (value, record) =>
    //     record.additionalPart1 ? record.additionalPart1.includes(value) : false,
    // },
    // {
    //   title: "Add'l Qs Part 2",
    //   dataIndex: "additionalPart2",
    //   sorter: (a, b) =>
    //     new Date(a.additionalpart2) - new Date(b.additionalPart2),
    //   filters: _.uniqWith(
    //     filterData(groupListData)((i) => i.additionalPart2),
    //     _.isEqual
    //   ),
    //   filteredValue: filteredInfo.additionalPart2 || null,
    //   filterSearch: true,
    //   onFilter: (value, record) =>
    //     record.additionalPart2 ? record.additionalPart2.includes(value) : false,
    // },
    // {
    //   title: "Applications",
    //   dataIndex: "completed",
    //   sorter: (a, b) => new Date(a.completed) - new Date(b.completed),
    //   filters: _.uniqWith(
    //     filterData(groupListData)((i) => i.completed),
    //     _.isEqual
    //   ),
    //   filteredValue: filteredInfo.completed  ,
    //   filterSearch: true,
    //   onFilter: (value, record) => record?.completed == value,
    // },
    {
      title: "Applications",
      dataIndex: ["completed", "respondent"],
      sorter: (a, b) => new Date(a.completed) - new Date(b.completed),

      // filters: _.uniqWith(
      //   filterData(groupListData)((i) => i.completed),
      //   _.isEqual
      // ).map((item) => ({ text: item, value: item })),
      filteredValue: filteredInfo?.completed,
      // filterSearch: false,
      // onFilter: (value, record) => {
      //   return record?.completed === value;
      // },
      render: (text, record) => {
        return `${record.completed} / ${record.respondent}`;
      },
    },
    {
      title: "Copy Code",
      render: (record) => (
        <div class="dropdown">
          <img
            src={threeDots}
            alt=""
            type="button"
            id="dropdownMenuButton"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          />
          <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <li
              class="dropdown-item"
              style={{ cursor: "pointer" }}
              onClick={() => copyCode(record?.code)}
            >
              Copy Link
            </li>
            <li
              class="dropdown-item"
              style={{ cursor: "pointer" }}
              onClick={() =>
                codeRespondendHandler(
                  record?.code,
                  record?.company,
                  record?.designation,
                  record?.reason
                )
              }
            >
              View Respondents
            </li>
            {localStorage.getItem("admin") &&
              JSON.parse(localStorage.getItem("admin")) == "Admin" && (
                <li
                  class="dropdown-item"
                  style={{ cursor: "pointer" }}
                  onClick={() => getEditData(record.code)}
                >
                  Edit
                </li>
              )}
          </div>
        </div>
      ),
    },
  ];
  const [checked, setChecked] = useState(true);
  const handleClickcheck = () => {
    setChecked(!checked);
  };

  const toggleSwitch = () => {
    setIsActive(!isActive);
  };
  const toggleSwitchWindow = () => {
    setIsActivewin(!isActivewin);
  };

  const getEditData = (code) => {
    setIsEdit(true);

    simpleGetCallWithErrorResponse(ApiConfig.GET_CODE_DETAILS + code).then(
      (res) => {
        console.log(res);

        if (res?.json?.succeded) {
          setDetails({
            isActive: res?.json?.response?.isActive,
            id: res?.json?.response?.id,
            accessCode: code,
            reason: res?.json?.response?.reason,
            company: res?.json?.response?.company,
            designation: res?.json?.response?.position,
            additionalQs1: res?.json?.response?.additionalPart1,
            additionalQs2: res?.json?.response?.primaryMatch,
            primaryMatch: res?.json?.response?.primaryMatch,
            secoundryMatch: res?.json?.response?.secondaryMatch,
            primaryProfile: res?.json?.response?.primaryId,
            secoundryProfile: res?.json?.response?.secondaryId,
            association: res?.json?.response?.codeAssociation,

            questionOne: res?.json?.response?.question1,
            questionTwo: res?.json?.response?.question2,
            questionThree: res?.json?.response?.question3,

            address: res?.json?.response?.addressOfSheet,
            linktoAssess: res?.json?.response?.linkToAssessment,
            from : res?.json?.response?.fromEmail,
            idealExp: res?.json?.response?.idealExperience,
            eduLevel: res?.json?.response?.idealEducationLevel,
            eduArea: res?.json?.response?.idealEducationArea,
            industry: res?.json?.response?.idealIndustry,
            salaryRange: res?.json?.response?.idealSalaryRange,
            startDate: res?.json?.response?.idealStartDate
              .split("T")[0]
              .split("-")
              .join("-"),
          });
          handleShowAddGrp();
          // handleShow();

          if (res?.json?.response?.reason === "Job Application") {
            setShowEmails(res?.json?.response?.users);

            if (res?.json?.response?.profilePer) {
              setInitialProfiles(res?.json?.response?.profilePer);
            }
          }
        } else {
          toastMsg("error", res?.json?.message);
        }
      }
    );
  };

  const [showAddGrp, setShowAddGrp] = useState(null);
  const [showModal, setShowModal] = useState(null);

  const handleShowAddGrp = () => {
    setShowModal(true);
    handleShow();
    // setShowAddGrp(true);
  };

  const [areaList, setAreaList] = useState([]);

  useEffect(() => {
    getAllspecialization();
    getAllIndustryList();
    getEmailList();
  }, []);

  const [industryList, setIndustryList] = useState([]);
  const getAllIndustryList = () => {
    simpleGetCall(ApiConfig.GET_INDUSTRY_LIST)
      .then((res) => {
        if (res.succeded) {
          setIndustryList(res?.response);
        } else {
          setIndustryList([]);
        }
      })
      .catch((err) => console.log(err));
  };
  const getAllspecialization = () => {
    simpleGetCall(ApiConfig.GET_AREA_OF_EDUCATION)
      .then((res) => {
        if (res.succeded) {
          setAreaList(res.response);
          console.log(res.response);
        } else {
          setAreaList([]);
        }
      })
      .catch((err) => console.log(err));
  };

  // const [filteredGrpListData, setFilteredGrpListData] = useState([]);
  // const [filterVal, setFilterVal] = useState("");
  // const handleFilterVal = (e) => {
  //   const { value } = e.target;
  //   setFilterVal(value);
  //   setFilteredGrpListData(groupListData.filter(x => x.company.toLowerCase().includes(value) || x.designation.toLowerCase().includes(value)));
  // }

  const [emailList, setEmailList] = useState([]);
  const getEmailList = () => {
    simpleGetCallWithErrorResponse(ApiConfig.GET_AUTHENTICATED_SENDERS)
      .then((res) => {
        console.log(res.json);
        
        setEmailList(res.json);
      })
      .catch((err) => console.log(err));
  };

  return (
    <>
      {!showModal && (
        <>
          {" "}
          <div className="row justify-content-between mt-4">
            <div className="col-2 my-auto">
              {/* <div className="canLisIconBox p-0 d-flex">
                <input
                  type="text"
                  className="bg-white border-0 search-input pe-2 rounded-2"
                />
              </div> */}
            </div>

            <div className="col-10 d-flex justify-content-end my-auto">
              {/* <div className="col-3 my-auto">
                <input
                  value={filterVal}
                  onChange={handleFilterVal}
                  type="text"
                  style={{height:"35px"}}
                  className="bg-white border search-input pe-2 rounded-2"
                />
              </div> */}
                <div className="col-3 my-auto">
                  <div className="row">
                    <div className="col-4 text-nowrap toggle-label text-end">
                      Inactive Only
                    </div>
                    <div
                      onClick={toggleSwitch}
                      className={`col-2 toggle-switch ${
                        isActive ? "active" : ""
                      }`}
                    >
                      <div className="toggle-circle"></div>
                    </div>
                    <div
                      className="col-1 text-nowrap toggle-label"
                      style={{ marginLeft: "-12px" }}
                    >
                      Active Only
                    </div>
                  </div>
                </div>

                <div className="col-2">
                  {localStorage.getItem("admin") &&
                    JSON.parse(localStorage.getItem("admin")) === "Admin" && (
                      <button
                        className="ml-4 btnVldt"
                        onClick={handleShowAddGrp}
                      >
                        Add Group
                      </button>
                    )}
                </div>
            </div>
          </div>
          <div className="candListBox ">
            <div className="candListHdr">
              <div className="canLisTxtStyl">
                <p>Group List</p>
              </div>

              <div className="grouplist-table">
                <Table
                  size="large"
                  columns={columns}
                  className="grouplisttable-alljobs"
                  // dataSource={filteredGrpListData.length>0? filteredGrpListData : groupListData}
                  dataSource={groupListData}
                  onChange={handleChange}
                  pagination={paginationConfig}
                ></Table>
              </div>

              {/* <div className="canLisSearchArea">
          <div className="searchBox">
            <img src={SearchIcon} className="boxIcon" alt="Logo" />
            <Form.Control
              type="text"
              className="searchForm.Control"
              placeholder="Search"
              // readOnly
            />
          </div>
          <div className="canLisIconBox">
            <img src={filterLines} className="boxIcon" alt="Logo" />
          </div>
          <div className="canLisIconBox">
            <img src={downloadIcon} className="boxIcon" alt="Logo" />
          </div>
          <div className="canLisIconBox">
            <img src={trashIcon} className="boxIcon" alt="Logo" />
          </div>
          {/* <button className="uploadBtn" onClick={handleUploadClick}>
            <img src={plusIcon} className="boxIcon" alt="Logo" />
            <p>Upload</p>
            <Form.Control
              type="file"
              className="fileForm.Control"
              ref={fileForm.ControlRef}
              onChange={handleFileChange}
              style={{ display: "none" }}
              accept=".pdf,.doc,.docx"
            />
          </button> }
        </div> */}
            </div>
          </div>{" "}
        </>
      )}

      {showModal && (
        <div
          // show={show}
          // centered
          // onHide={handleClose}
          // dialogClassName="custom-modal"
          // size={`${details.reason === "Job Application" ? "xl" : "lg"}`}
          style={{
            backgroundColor: "white",
            height: "auto",
            marginTop: "14px",
            margin: "10px",
            padding: "19px",
            borderRadius: "10px",
          }}
        >
          <div>
            <div closeButton className="custom-close-btn">
              <div className="d-flex justify-content-between">
                <h5 className="custom-modal-title">Add/ Edit Group</h5>
                <button className="btn-close" onClick={handleClose}></button>
              </div>
              <hr />
            </div>
            <div>
              <Form noValidate validated={valiteded} onSubmit={handleSubmit}>
                <div className="row">
                  <div
                    className={`${
                      details.reason === "Job Application" ? "col-6" : "col-12"
                    }`}
                  >
                    <div className="d-flex align-items-center">
                      <div className="col-6">
                        <Form.Group controlId="accessCode" className="mb-3">
                          <Form.Label>Access Code</Form.Label>
                          <Form.Control
                            disabled={isEdit}
                            type="text"
                            placeholder="Access Code"
                            id="accessCode"
                            value={details.accessCode}
                            minLength={7}
                            maxLength={7}
                            required
                            isInvalid={
                              valiteded && details.accessCode.length != 7
                            }
                            onChange={(e) =>
                              handleChage(
                                "accessCode",
                                e.target.value.toUpperCase()
                              )
                            }
                          />
                          <Form.Control.Feedback type="invalid">
                            Please Enter 7 Digit Valid Code!
                          </Form.Control.Feedback>
                        </Form.Group>
                      </div>

                      {/* <div className="col-3 ms-5 d-flex mt-3">
                    <Form.Check
                      type="checkbox"
                      onClick={handleClickcheck}
                    ></Form.Check>
                    {checked ? (
                      <Form.Label className="ms-3">Deactive</Form.Label>
                    ) : (
                      <Form.Label className="ms-3">Active</Form.Label>
                    )}
                  </div> */}
                      <div className="col-auto ms-5 mt-3">
                        <div className="App-header">
                          <div
                            className="d-flex "
                            onClick={() => {
                              handleChage("isActive", !details?.isActive);
                            }}
                          >
                            <div className="col-3 me-3 text-nowrap toggle-label">
                              Inactive
                            </div>
                            <div
                              className={`col-6 toggle-switch  ${
                                details?.isActive ? "active" : ""
                              }`}
                            >
                              <div className="toggle-circle"></div>
                            </div>
                            <div className="col-3 me-3 text-nowrap toggle-label">
                              Active
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/*                 
                <Form.Group controlId="accessCode" className="mb-3">
                  <Form.Label>Access Code</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Access Code"
                    id="accessCode"
                    value={details.accessCode}
                    minLength={7}
                    maxLength={7}
                    required
                    isInvalid={valiteded && details.accessCode.length != 7}
                    onChange={(e) =>
                      handleChage("accessCode", e.target.value.toUpperCase())
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    Please Enter 7 Digit Valid Code!
                  </Form.Control.Feedback>
                </Form.Group> */}
                    <Form.Group controlId="reason" className="mb-3">
                      <Form.Label>Application Type </Form.Label>
                      <Form.Select
                        disabled={isEdit}
                        required
                        value={details?.reason}
                        onChange={(e) => {
                          setValiteded(false);
                          handleChage("reason", e.target.value);
                        }}
                      >
                        <option disabled value={""}>
                          Select reason
                        </option>
                        {/* <option value="Individual User">Individual User</option> */}
                        <option value="Job Application">Job Application</option>
                        <option value="Internal Assessment">
                          Internal Assessment
                        </option>
                        <option value="Investor As Individual User">
                          Investor As Individual User
                        </option>
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        Please Select Reason!
                      </Form.Control.Feedback>
                      {/* <Form.Control type="email" placeholder="Reason" /> */}
                    </Form.Group>

                    {details?.reason !== "Individual User" &&
                      details?.reason !== "Investor" &&
                      details?.reason !== "" && (
                        <>
                          <Form.Group controlId="company" className="mb-3">
                            <Form.Label>Company</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Company"
                              required
                              value={details?.company}
                              onChange={(e) =>
                                handleChage("company", e.target.value)
                              }
                            />
                            <Form.Control.Feedback type="invalid">
                              Please Enter Company Name!
                            </Form.Control.Feedback>
                          </Form.Group>
                          {/* <Form.Group controlId="designation" className="mb-3">
                        <Form.Label>Designation</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Designation"
                          required
                          value={details?.designation}
                          onChange={(e) =>
                            handleChage("designation", e.target.value)
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          Please Enter Designation!
                        </Form.Control.Feedback>
                      </Form.Group> */}

                          {/* 
                  <Form.Group controlId="primary profile" className="mb-3">
                    <Form.Label>Primary Profile </Form.Label>
                    <Form.Select
                      required
                      value={details?.primaryProfile}
                      onChange={(e) =>
                        handleChage("primaryProfile", e.target.value)
                      }
                    >
                      <option disabled value={""}>
                        Select Primary Profile
                      </option>
                      <option value="1">Pioneer</option>
                      <option value="2">Influencer </option>
                      <option value="3">Team Player</option>
                      <option value="4">Logical Thinker</option>

                      <option value="5">Persuader</option>
                      <option value="7">perfectionist</option>
                      <option value="8">Collaborater</option>
                      <option value="9">Accessor</option>
                      <option value="10">Implementor</option>
                      <option value="11">Motivator</option>
                      <option value="12">Leader</option>
                      <option value="13">Administrator</option>
                      <option value="14">Mediator</option>
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      Please Select Prmary Profile!
                    </Form.Control.Feedback>
                   
                  </Form.Group>

                  <Form.Group controlId="secoundry profile" className="mb-3">
                    <Form.Label>Secoundry Profile </Form.Label>
                    <Form.Select
                      required
                      value={details?.secoundryProfile}
                      onChange={(e) =>
                        handleChage("secoundryProfile", e.target.value)
                      }
                    >
                      <option disabled value={""}>
                        Select Secoundry Profile
                      </option>
                      <option value="1">Pioneer</option>
                      <option value="2">Influencer </option>
                      <option value="3">Team Player</option>
                      <option value="4">Logical Thinker</option>

                      <option value="5">Persuader</option>
                      <option value="7">perfectionist</option>
                      <option value="8">Collaborater</option>
                      <option value="9">Accessor</option>
                      <option value="10">Implementor</option>
                      <option value="11">Motivator</option>
                      <option value="12">Leader</option>
                      <option value="13">Administrator</option>
                      <option value="14">Mediator</option>
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      Please Select Prmary Profile!
                    </Form.Control.Feedback>
                  
                  </Form.Group> */}
                        </>
                      )}
                    {/* {details.reason === "Internal Assessment" && (
                  <>
                    <Form.Group controlId="primary profile" className="mb-3">
                      <Form.Label>Primary Profile </Form.Label>
                      <Form.Select
                        required
                        value={details?.primaryProfile}
                        onChange={(e) =>
                          handleChage("primaryProfile", e.target.value)
                        }
                      >
                        <option disabled value={""}>
                          Select Primary Profile
                        </option>
                        <option value="1">Pioneer</option>
                        <option value="2">Influencer </option>
                        <option value="3">Team Player</option>
                        <option value="4">Logical Thinker</option>

                        <option value="5">Persuader</option>
                        <option value="7">perfectionist</option>
                        <option value="8">Collaborater</option>
                        <option value="9">Accessor</option>
                        <option value="10">Implementor</option>
                        <option value="11">Motivator</option>
                        <option value="12">Leader</option>
                        <option value="13">Administrator</option>
                        <option value="14">Mediator</option>
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        Please Select Prmary Profile!
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="secoundry profile" className="mb-3">
                      <Form.Label>Secoundry Profile </Form.Label>
                      <Form.Select
                        required
                        value={details?.secoundryProfile}
                        onChange={(e) =>
                          handleChage("secoundryProfile", e.target.value)
                        }
                      >
                        <option disabled value={""}>
                          Select Secoundry Profile
                        </option>
                        <option value="1">Pioneer</option>
                        <option value="2">Influencer </option>
                        <option value="3">Team Player</option>
                        <option value="4">Logical Thinker</option>

                        <option value="5">Persuader</option>
                        <option value="7">perfectionist</option>
                        <option value="8">Collaborater</option>
                        <option value="9">Accessor</option>
                        <option value="10">Implementor</option>
                        <option value="11">Motivator</option>
                        <option value="12">Leader</option>
                        <option value="13">Administrator</option>
                        <option value="14">Mediator</option>
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        Please Select Prmary Profile!
                      </Form.Control.Feedback>
                    </Form.Group>
                  </>
                )} */}
                    {details.reason === "Job Application" && (
                      <div className="mt-3">
                        <Form.Group controlId="designation" className="mb-3">
                          <Form.Label>Designation</Form.Label>
                          <Form.Control
                            type="text"
                            required
                            placeholder="Designation"
                            value={details?.designation}
                            onChange={(e) =>
                              handleChage("designation", e.target.value)
                            }
                          />
                          <Form.Control.Feedback type="invalid">
                            Please Enter Designation!
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-3">
                          <Form.Label>
                            Custom Question 1 (Answer is Yes/No only)
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Custom Question 1"
                            value={details.questionOne}
                            onChange={(e) =>
                              handleChage("questionOne", e.target.value)
                            }
                          />
                          <Form.Text>
                            leave blank if you wish to not use
                          </Form.Text>
                        </Form.Group>
                        <Form.Group className="mb-3">
                          <Form.Label>
                            Custom Question 2 (Answer is Yes/No only)
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Custom Question 2"
                            value={details.questionTwo}
                            onChange={(e) =>
                              handleChage("questionTwo", e.target.value)
                            }
                          />
                          <Form.Text>
                            leave blank if you wish to not use
                          </Form.Text>
                        </Form.Group>
                        <Form.Group className="mb-3">
                          <Form.Label>
                            Custom Question 3 (Answer is Yes/No only)
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Custom Question 3"
                            value={details.questionThree}
                            onChange={(e) =>
                              handleChage("questionThree", e.target.value)
                            }
                          />
                          <Form.Text>
                            leave blank if you wish to not use
                          </Form.Text>
                        </Form.Group>

                        <Form.Group className="mb-3">
                          <Form.Label>
                            Address of the Sheet for accessing the Score(Score
                            in Column C, Email in Column B)
                          </Form.Label>
                          <Form.Control
                            type="text"
                            value={details.address}
                            onChange={(e) =>
                              handleChage("address", e.target.value)
                            }
                          />
                          {!details.address && (
                            <Form.Text>
                              Please enter a valid google document link
                            </Form.Text>
                          )}
                        </Form.Group>

                        <Form.Group className="mb-3">
                          <Form.Label>Link to Assessment</Form.Label>
                          <Form.Control
                            type="text"
                            value={details.linktoAssess}
                            onChange={(e) =>
                              handleChage("linktoAssess", e.target.value)
                            }
                            // isInvalid={!!error}
                          />
                          <Form.Text type="">
                            {!details.linktoAssess && error}
                          </Form.Text>
                        </Form.Group>


                        <Form.Group controlId="idealExp" className="mb-3">
                          <Form.Label>Ideal Experience</Form.Label>
                          <Form.Control
                            type="number"
                            value={details?.idealExp}
                            onChange={(e) =>
                              handleChage("idealExp", e.target.value)
                            }
                            required
                          ></Form.Control>
                          <Form.Control.Feedback type="invalid">
                            Please Enter Ideal Experience
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group controlId="eduLevel" className="mb-3">
                          <Form.Label>Ideal Education Level(s)</Form.Label>
                          <Form.Select
                            value={details.eduLevel}
                            onChange={(e) =>
                              handleChage("eduLevel", e.target.value)
                            }
                            required
                          >
                            <option selected value="" disabled>
                              select ideal Education Level
                            </option>
                            <option value="High School">High School</option>
                            <option value="Diploma">Diploma</option>
                            <option value="PG Diploma">PG Diploma</option>
                            <option value="Bachelor's">Bachelor's</option>
                            <option value="Master's">Master's</option>
                            <option value="PhD">PhD</option>
                          </Form.Select>
                          <Form.Control.Feedback type="invalid">
                            Please Select the Ideal Education Level
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group controlId="eduArea" className="mb-3">
                          <Form.Label>Ideal Education Area(s)</Form.Label>
                          <Form.Select
                            value={details.eduArea}
                            onChange={(e) =>
                              handleChage("eduArea", e.target.value)
                            }
                            required
                          >
                            <option selected value="" disabled>
                              select ideal Eduction Areas
                            </option>
                            {areaList.map((x) => (
                              <option value={x.specializationName}>
                                {x.specializationName}
                              </option>
                            ))}
                          </Form.Select>
                          <Form.Control.Feedback type="invalid">
                            Please Select the Ideal Education Area
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group controlId="industry" className="mb-3">
                          <Form.Label>Ideal Industry(s)</Form.Label>
                          <Form.Select
                            value={details.industry}
                            onChange={(e) =>
                              handleChage("industry", e.target.value)
                            }
                            required
                          >
                            <option selected value="" disabled>
                              select ideal Industry
                            </option>
                            {industryList.map((x) => (
                              <option value={x.label}>{x.label}</option>
                            ))}
                          </Form.Select>
                          <Form.Control.Feedback type="invalid">
                            Please Select the Industry
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group controlId="salaryRange" className="mb-3">
                          <Form.Label>Ideal Salary Range(s)</Form.Label>
                          <Form.Select
                            value={details.salaryRange}
                            onChange={(e) =>
                              handleChage("salaryRange", e.target.value)
                            }
                            required
                          >
                            <option selected value="" disabled>
                              select ideal Salary Range
                            </option>
                            <option value="Below 3 Lakhs per year">
                              Below 3 Lakhs per year
                            </option>
                            <option value="3 to 5 Lakhs per year">
                              3 to 5 Lakhs per year
                            </option>
                            <option value="5 to 7 Lakhs per year">
                              5 to 7 Lakhs per year
                            </option>
                            <option value="7 to 9 Lakhs per year">
                              7 to 9 Lakhs per year
                            </option>
                            <option value="9 to 12 Lakhs per year">
                              9 to 12 Lakhs per year
                            </option>
                            <option value="12 to 15 Lakhs per year">
                              12 to 15 Lakhs per year
                            </option>
                            <option value="15 to 18 Lakhs per year">
                              15 to 18 Lakhs per year
                            </option>
                            <option value="18 to 22 Lakhs per year">
                              18 to 22 Lakhs per year
                            </option>
                            <option value="22 to 26 Lakhs per year">
                              22 to 26 Lakhs per year
                            </option>
                            <option value="26 to 30 Lakhs per year">
                              26 to 30 Lakhs per year
                            </option>
                            <option value="30 to 35 Lakhs per year">
                              30 to 35 Lakhs per year
                            </option>
                            <option value="35 to 40 Lakhs per year">
                              35 to 40 Lakhs per year
                            </option>
                            <option value="40 to 45 Lakhs per year">
                              40 to 45 Lakhs per year
                            </option>
                            <option value="45 to 50 Lakhs per year">
                              45 to 50 Lakhs per year
                            </option>
                            <option value="50 to 60 Lakhs per year">
                              50 to 60 Lakhs per year
                            </option>
                            <option value="60 to 70 Lakhs per year">
                              60 to 70 Lakhs per year
                            </option>
                            <option value="70 to 80 Lakhs per year">
                              70 to 80 Lakhs per year
                            </option>
                            <option value="80 Lakhs to 1 Cr per year">
                              80 Lakhs to 1 Cr per year
                            </option>
                            <option value="Above 1 Cr per year">
                              Above 1 Cr per year
                            </option>
                          </Form.Select>
                          <Form.Control.Feedback type="invalid">
                            Please Select the Ideal Salary Range
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group controlId="startDate" className="mb-3">
                          <Form.Label>Ideal Start Date</Form.Label>
                          <Form.Control
                            type="date"
                            value={details.startDate}
                            onChange={(e) =>
                              handleChage("startDate", e.target.value)
                            }
                            required
                          ></Form.Control>
                          <Form.Control.Feedback type="invalid">
                            Please Select the Ideal Start Date
                          </Form.Control.Feedback>
                        </Form.Group>
                      </div>
                    )}
                  </div>

                  <div className="col-6">
                    {details.reason === "Job Application" && (
                      <>
                        {
                          <table className="group-table table table-hover mb-5">
                            <thead>
                              <tr>
                                <th>Profile</th>
                                <th>Match</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>Pioneer (1)</td>
                                <td className="d-flex justify-content-center align-items-center">
                                  <Form.Control
                                    type="number"
                                    className="me-1"
                                    required
                                    style={{ width: "65px" }}
                                    value={initialProfiles?.pioneer}
                                    onChange={(e) => {
                                      if (
                                        (Number(e.target.value) <= 100 &&
                                          Number(e.target.value >= 0)) ||
                                        e.target.value === ""
                                      ) {
                                        handleProfiles(
                                          "pioneer",
                                          e.target.value
                                        );
                                      }
                                    }}
                                  />
                                  %
                                </td>
                              </tr>
                              <tr>
                                <td>Influencer (2)</td>
                                <td className="d-flex justify-content-center align-items-center">
                                  <Form.Control
                                    style={{ width: "65px" }}
                                    type="number"
                                    className="me-1"
                                    required
                                    value={initialProfiles?.influencer}
                                    onChange={(e) => {
                                      if (
                                        (Number(e.target.value) <= 100 &&
                                          Number(e.target.value >= 0)) ||
                                        e.target.value === ""
                                      ) {
                                        handleProfiles(
                                          "influencer",
                                          e.target.value
                                        );
                                      }
                                    }}
                                  ></Form.Control>
                                  %
                                </td>
                              </tr>
                              <tr>
                                <td>Team Player (3)</td>
                                <td className="d-flex justify-content-center align-items-center">
                                  <Form.Control
                                    type="number"
                                    className="me-1"
                                    style={{ width: "65px" }}
                                    required
                                    value={initialProfiles.teamPlayer}
                                    onChange={(e) => {
                                      if (
                                        (Number(e.target.value) <= 100 &&
                                          Number(e.target.value >= 0)) ||
                                        e.target.value === ""
                                      ) {
                                        handleProfiles(
                                          "teamPlayer",
                                          e.target.value
                                        );
                                      }
                                    }}
                                  ></Form.Control>
                                  %
                                </td>
                              </tr>
                              <tr>
                                <td>Logical Thinker (4)</td>
                                <td className="d-flex justify-content-center align-items-center">
                                  <Form.Control
                                    type="number"
                                    className="me-1"
                                    style={{ width: "65px" }}
                                    required
                                    value={initialProfiles.logicalThinker}
                                    onChange={(e) => {
                                      if (
                                        (Number(e.target.value) <= 100 &&
                                          Number(e.target.value >= 0)) ||
                                        e.target.value === ""
                                      ) {
                                        handleProfiles(
                                          "logicalThinker",
                                          e.target.value
                                        );
                                      }
                                    }}
                                  ></Form.Control>
                                  %
                                </td>
                              </tr>
                              <tr>
                                <td>Persuader (5)</td>
                                <td className="d-flex justify-content-center align-items-center">
                                  <Form.Control
                                    type="number"
                                    className="me-1"
                                    style={{ width: "65px" }}
                                    required
                                    value={initialProfiles.persuader}
                                    onChange={(e) => {
                                      if (
                                        (Number(e.target.value) <= 100 &&
                                          Number(e.target.value >= 0)) ||
                                        e.target.value === ""
                                      ) {
                                        handleProfiles(
                                          "persuader",
                                          e.target.value
                                        );
                                      }
                                    }}
                                  ></Form.Control>
                                  %
                                </td>
                              </tr>
                              <tr>
                                <td>Perfectionist (6)</td>
                                <td className="d-flex justify-content-center align-items-center">
                                  <Form.Control
                                    type="number"
                                    className="me-1"
                                    style={{ width: "65px" }}
                                    required
                                    value={initialProfiles.perfectionist}
                                    onChange={(e) => {
                                      if (
                                        (Number(e.target.value) <= 100 &&
                                          Number(e.target.value >= 0)) ||
                                        e.target.value === ""
                                      ) {
                                        handleProfiles(
                                          "perfectionist",
                                          e.target.value
                                        );
                                      }
                                    }}
                                  ></Form.Control>
                                  %
                                </td>
                              </tr>
                              <tr>
                                <td>Collaborater (7)</td>
                                <td className="d-flex justify-content-center align-items-center">
                                  <Form.Control
                                    type="number"
                                    className="me-1"
                                    style={{ width: "65px" }}
                                    required
                                    value={initialProfiles.collaborator}
                                    onChange={(e) => {
                                      if (
                                        (Number(e.target.value) <= 100 &&
                                          Number(e.target.value >= 0)) ||
                                        e.target.value === ""
                                      ) {
                                        handleProfiles(
                                          "collaborator",
                                          e.target.value
                                        );
                                      }
                                    }}
                                  ></Form.Control>
                                  %
                                </td>
                              </tr>
                              <tr>
                                <td>Achiever (8)</td>
                                <td className="d-flex justify-content-center align-items-center">
                                  <Form.Control
                                    type="number"
                                    className="me-1"
                                    style={{ width: "65px" }}
                                    required
                                    value={initialProfiles.achiever}
                                    onChange={(e) => {
                                      if (
                                        (Number(e.target.value) <= 100 &&
                                          Number(e.target.value >= 0)) ||
                                        e.target.value === ""
                                      ) {
                                        handleProfiles(
                                          "achiever",
                                          e.target.value
                                        );
                                      }
                                    }}
                                  ></Form.Control>
                                  %
                                </td>
                              </tr>
                              <tr>
                                <td>Accessor (9)</td>
                                <td className="d-flex justify-content-center align-items-center">
                                  <Form.Control
                                    type="number"
                                    className="me-1"
                                    style={{ width: "65px" }}
                                    required
                                    value={initialProfiles.assessor}
                                    onChange={(e) => {
                                      if (
                                        (Number(e.target.value) <= 100 &&
                                          Number(e.target.value >= 0)) ||
                                        e.target.value === ""
                                      ) {
                                        handleProfiles(
                                          "assessor",
                                          e.target.value
                                        );
                                      }
                                    }}
                                  ></Form.Control>
                                  %
                                </td>
                              </tr>
                              <tr>
                                <td>Implementor (10)</td>
                                <td className="d-flex justify-content-center align-items-center">
                                  <Form.Control
                                    type="number"
                                    className="me-1"
                                    style={{ width: "65px" }}
                                    required
                                    value={initialProfiles.implementor}
                                    onChange={(e) => {
                                      if (
                                        (Number(e.target.value) <= 100 &&
                                          Number(e.target.value >= 0)) ||
                                        e.target.value === ""
                                      ) {
                                        handleProfiles(
                                          "implementor",
                                          e.target.value
                                        );
                                      }
                                    }}
                                  ></Form.Control>
                                  %
                                </td>
                              </tr>
                              <tr>
                                <td>Motivator (11)</td>
                                <td className="d-flex justify-content-center align-items-center">
                                  <Form.Control
                                    type="number"
                                    className="me-1"
                                    style={{ width: "65px" }}
                                    required
                                    value={initialProfiles.motivator}
                                    onChange={(e) => {
                                      if (
                                        (Number(e.target.value) <= 100 &&
                                          Number(e.target.value >= 0)) ||
                                        e.target.value === ""
                                      ) {
                                        handleProfiles(
                                          "motivator",
                                          e.target.value
                                        );
                                      }
                                    }}
                                  ></Form.Control>
                                  %
                                </td>
                              </tr>
                              <tr>
                                <td>Leader (12)</td>
                                <td className="d-flex justify-content-center align-items-center">
                                  <Form.Control
                                    type="number"
                                    className="me-1"
                                    style={{ width: "65px" }}
                                    required
                                    value={initialProfiles.leader}
                                    onChange={(e) => {
                                      if (
                                        (Number(e.target.value) <= 100 &&
                                          Number(e.target.value >= 0)) ||
                                        e.target.value === ""
                                      ) {
                                        handleProfiles(
                                          "leader",
                                          e.target.value
                                        );
                                      }
                                    }}
                                  ></Form.Control>
                                  %
                                </td>
                              </tr>
                              <tr>
                                <td>Administrator (13)</td>
                                <td className="d-flex justify-content-center align-items-center">
                                  <Form.Control
                                    type="number"
                                    className="me-1"
                                    style={{ width: "65px" }}
                                    required
                                    value={initialProfiles.administrator}
                                    onChange={(e) => {
                                      if (
                                        (Number(e.target.value) <= 100 &&
                                          Number(e.target.value >= 0)) ||
                                        e.target.value === ""
                                      ) {
                                        handleProfiles(
                                          "administrator",
                                          e.target.value
                                        );
                                      }
                                    }}
                                  ></Form.Control>
                                  %
                                </td>
                              </tr>
                              <tr>
                                <td>Mediator (14)</td>
                                <td className="d-flex justify-content-center align-items-center">
                                  <Form.Control
                                    type="number"
                                    className="me-1"
                                    style={{ width: "65px" }}
                                    required
                                    value={initialProfiles.mediator}
                                    onChange={(e) => {
                                      if (
                                        (Number(e.target.value) <= 100 &&
                                          Number(e.target.value >= 0)) ||
                                        e.target.value === ""
                                      ) {
                                        handleProfiles(
                                          "mediator",
                                          e.target.value
                                        );
                                      }
                                    }}
                                  ></Form.Control>
                                  %
                                </td>
                              </tr>
                              <tr>
                                <td>Versatile (15)</td>
                                <td className="d-flex justify-content-center align-items-center">
                                  <Form.Control
                                    type="number"
                                    className="me-1"
                                    style={{ width: "65px" }}
                                    required
                                    value={initialProfiles.versatile}
                                    onChange={(e) => {
                                      if (
                                        (Number(e.target.value) <= 100 &&
                                          Number(e.target.value >= 0)) ||
                                        e.target.value === ""
                                      ) {
                                        handleProfiles(
                                          "versatile",
                                          e.target.value
                                        );
                                      }
                                    }}
                                  ></Form.Control>
                                  %
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        }
                        {/* <Form.Group controlId="Primary match %" className="mb-3">
                  <Form.Label>Primary match %</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Primary match %"
                    value={details?.primaryMatch}
                    onChange={(e) =>
                      (e.target.value === "" ||
                        (Number(e.target.value) <= 100 &&
                          Number(e.target.value) > 0)) &&
                      handleChage("primaryMatch", e.target.value)
                    }
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    Please Enter Primary Match!
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="Secondary match %" className="mb-3">
                  <Form.Label>Secondary match %</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Secondary match %"
                    required
                    value={details?.secoundryMatch}
                    onChange={(e) =>
                      (e.target.value === "" ||
                        (Number(e.target.value) <= 100 &&
                          Number(e.target.value) > 0)) &&
                      handleChage("secoundryMatch", e.target.value)
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    Please Enter Secoundry Match!
                  </Form.Control.Feedback>
                </Form.Group> */}
                 <Form.Group className="mb-3">
                          <Form.Label>Email Id to be used to send communications to candidate</Form.Label>
                          <Form.Select value={details.from} onChange={(e) => handleChage("from", e.target.value)}>
                            <option value="" selected disabled>Select Email</option>
                            {
                              emailList.filter(x=> x.verified===true).map((email, index) => (
                                <option value={email.from_Email} key={index}>{email.from_Email}</option>
                              ))
                            }
                          </Form.Select>
                          {/* <Form.Control
                            type="email"
                            value={details.from}
                            onChange={(e) =>
                              handleChage("from", e.target.value)
                            }
                          /> */}
                          {/* <Form.Text type="">
                            {!details.linktoAssess && error}
                          </Form.Text> */}
                        </Form.Group>
                        <div>
                        <Form.Group
                          controlId="Primary match %"
                          className="mb-3 position-relative mt-3"
                        >
                          <div className="row justify-content-between mt-3">
                            <Form.Label>Job Access Group</Form.Label>
                            <div className="col">
                              <input
                                type="email"
                                className="form-control"
                                isInvalid={valiteded && showEmails.length}
                                placeholder="Job Access Group"
                                value={enterEmail}
                                onChange={(e) => setEnterEmail(e.target.value)}
                              />
                              {/* <Form.Control.Feedback type="invalid">
                              Please Add Company Users!
                            </Form.Control.Feedback> */}
                            </div>
                            <div className="col-2">
                              <Button className="" onClick={handleAddEmail}>
                                Add
                              </Button>
                            </div>
                          </div>

                          {
                            <div className="col-5 position-absolute start-0 mt-3">
                              {showEmails.map((email, index) => (
                                <span
                                  className="addbackgroundcolor px-2 py-1 rounded-5 me-3 text-nowrap"
                                  key={index}
                                >
                                  {email?.email}
                                  <button
                                    type="button"
                                    className="btn-close"
                                    style={{
                                      width: "5px",
                                      height: "7px",
                                      marginLeft: "4px",
                                      opacity: "0.8",
                                      marginBottom: "-5px",
                                    }}
                                    onClick={() => removeEmail(index)}
                                  ></button>
                                  {/* <img
                                src={close}
                                style={{ width: "15px", marginLeft: "5px" }}
                                onClick={() => removeEmail(index)}
                              /> */}
                                </span>
                              ))}
                            </div>
                          }
                        </Form.Group>
                        </div>
                        
                      </>
                    )}
                  </div>
                  <div className="d-flex justify-content-end">
                    <Button className="custom-register-btn" type="submit">
                      {isEdit ? "Update" : "Add"} Group
                    </Button>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default GroupList;
